import React from "react"
import Content from "../../../components/Content"
import Layout from "../../../components/Layout"
import ResultsMenu from "../../../components/ResultsMenu"
import Csv from "../../../components/Csv"

export default function Results() {
  return (
    <Layout>
      <ResultsMenu year={2021} />
      <Content>
        <Csv
          csvUrl="/results-2021.csv"
          columns={["Placering", "Namn", "Klubb", "Tid"]}
          separator=","
        />
      </Content>
    </Layout>
  )
}
